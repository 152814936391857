@import 'css/vendor/include-media';
@import "~mappy-breakpoints/mappy-breakpoints";

.checkout-disabled-overlay {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkout-disabled {
  opacity: 1;
  background-color: white;
  max-width: 50%;

  .content {
    border: 30px solid rgba(80, 146, 142, 0.7);
    border-radius: 0;
  }
  .body {
    position: relative;
    padding: 15px;
  }
  h3 {
    font-family: hk_groteskbold;
    font-size: 32px;
    color: #a27d57;
    font-weight: 400;
  }
  p {
    white-space: pre-line;
  }
  a {
    color: #a27d57 !important;
    background: none !important;
    display: inline-block;
    text-align: left;
    font-size: 14px;
  }
}
.disabled {
  height: 100%;
  overflow: hidden
}
.checkout-container {
  width: 100%;
  padding: 0;
  @include mappy-bp(1024px) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @include mappy-bp(1200px) {
    padding-left: 10%;
    padding-right: 10%;
  }
  .flash-messages {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;

    .alert {
      height: 100%;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.4;
    }

    .alert-danger {
      background-color: $milano-red;
    }

    .close {
      position: absolute;
      right: 0;
      padding: 15px;
    }
  }

  .header {
    max-width: 1240px !important;
    margin: 0 auto;
    height: 250px;
  }

  .upperRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    @include mappy-bp(min-width 1024px) {
      margin-left: 15px;
      margin-right: 15px;
    }

    h1 {
      color: $brown;
      font-size: 32px;
      margin: 0;
      font-family: 'hk_groteskbold';
    }

    .logo {
      width: 100px;
      margin: 25px 0;
      position: relative;
      z-index: 99;
    }

    .custServiceInfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: right;
      span {
        padding-top: 20px;
        &:first-of-type {
          font: 15px noto_serifitalic;
          color: $green;
        }
        &:last-of-type {
          font-size: 14px;
        }
      }
    }
  }

  .step-container {
    max-width: 1240px !important;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    @include mappy-bp(min-width 1024px) {
      padding-top: 0;
    }
    @include mappy-bp(max-width 769px) {
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
    @include mappy-bp(max-width 490px) {
      margin-top: 20px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .checkoutSteps {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fffaf5;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid $border-color;
    @include mappy-bp(min-width 1024px) {
      background: transparent;
      padding-top: 20px;
      padding-bottom: 10px;
      border-top: 0;
    }

    a:hover,
    a:active,
    a:focus {
      background-color: transparent!important;
    }

    .progress-steps {
      width: 70%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      @include mappy-bp(min-width 1024px) {
        width: 100%;
      }
    }

    li {
      width: 33%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      // @include mappy-bp(min-width 768px) {
      //   &:after {
      //     content: '';
      //     position: absolute;
      //     // left: 90%;
      //     width: 30%;
      //     height: 1px;
      //     background-color: $light-dark;
      //   }
      // }

      &:last-child {
        &:after {
          display: none;
        }
      }

      span {
        border: 2px solid $green;
        color: $green;
        background-color: #fff;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        font-family: 'noto_serifregular';
        font-size: 25px;
        text-align: center;
        padding: 25px;
        opacity: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      a {
        font-family: 'hk_groteskregular';
        font-size: 24px;
        color: $dark-text;
        padding: 0 !important;
        margin-bottom: 0;
        margin-left: 5%;
      }
    }

    .active {
      span {
        opacity: 1;
        background: $green;
        color: #fff;
      }

      a,
      a:hover {
        color: #000;
        background-color: transparent;
      }
    }

    .completed a {
      text-decoration: underline !important;

      &:hover {
        color: black !important;
      }
    }

    .clickable {
      cursor: pointer;
    }
  }

  .divisionLine {
    background-color: $light-silver;
    height: 1px;
    width: 104px;
  }
}

@include media('<=tablet') {
  .checkout-container {
    .header {
      height: 205px;
    }

    .upperRow {
      border-bottom: 1px solid $border-color;

      .logo {
        width: 70%;
      }

      h1 {
        width: 60%;
        text-align: center;
      }

      .custServiceInfo {
        width: 20%;
        word-wrap: break-word;

        span {
          padding: 0;

          &:last-child {
            display: none;
          }
        }
      }
    }

    .checkoutSteps {
      .progress-steps {
        width: 100%;

      }
    }
  }
}

@include mappy-bp(max-width 490px) {
  .checkout-container {
    .header {
      height: 100px;
      border-bottom: 0;
    }

    .upperRow {
      height: 70px;
      border-bottom: 0;

      a {
        width: 25%;
      }

      .logo {
        width: 80%;
        margin: 0;
        padding: 5px 0;
        object-position: center;
      }

      h1 {
        width: 50%;
        font-size: 24px;
      }

      .custServiceInfo {
        width: 25%;

        span {
          font-size: 12px !important;
        }
      }
    }

    .checkoutSteps {
      height: 50px;
      background-color: #FFFAF6;
      margin: 0;

      .progress-steps {
        &:after {
          display: none;
        }

        span {
          font-size: 16px;
          width: 30px;
          height: 30px;
          padding: 15px;
        }

        a {
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }
  }
}

#checkout_form_payment {
  position: relative;
}

.save-payment-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  @include mappy-bp(max-width 992px) {
    width: 100%;
  }
  &:first-of-type {
    margin-top: 10px;
  }
}

.save-payment-buttons {
  display: flex;
  justify-content: space-between;
  @include mappy-bp(max-width 990px) {
    margin-top: 15px;
  }
}

.save-payment-buttons.payment {
  @include mappy-bp(max-width 990px) {
    margin-right: -15px;
  }
}

.payment-back-to-cart {
  font-family: hk_groteskregular;
  font-size: 15px;
  color: #a27d57;
  padding-bottom: 0;
  &:hover {
    color: #a27d57;
    text-decoration: underline;
  }
}

.save-payment .save-payment-btn {
  @include mappy-bp(max-width 490px) {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.col-md-12.checkout-inside {
  padding: 0;
}

.or-string-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #bdbdbd;
  font-family: hk_groteskregular;
  font-size: 16px;
  @include mappy-bp(min-width 992px) {
    display: none;
  }
}

.payment-container-desktop {
  @include mappy-bp(992px) {
    margin-top: 40px;
  }
  @include mappy-bp(max-width 992px) {
    top: 370px;
    z-index: 10;
    width: 100%;
    flex-direction: column;
    .payment {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 20px;
      width: 100%;
      .btn {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
  .payment {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
  }
}

.buttons-confirm {
  padding-left: 10px;
  @include mappy-bp(max-width 992px) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: -5px;
    width: 100%;
    padding-left: 0;
    .btn {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

.on-terms-payment-header {
  margin-bottom: 30px;
  @include mappy-bp(max-width 992px) {
    margin-bottom: 20px;
  }
}
