@import 'css/vendor/include-media';
@import "~mappy-breakpoints/mappy-breakpoints";

#nuherbs {
  header {
    position: fixed;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: center;
    border-bottom: 0;
    background: url(../img/bg-header.png) no-repeat;
    background-size: cover;
    z-index: 100;

    @include mappy-bp(min-width 768px) {
      height: 75px;
      border-bottom: 1px solid $border-color;
    }

    @include mappy-bp(min-width 1024px) {
      height: 160px;
    }

    @include mappy-bp(max-width 768px) {
      height: 50px;
    }

    .navbar {
      width: 1240px;
      background: none;
      height: inherit;
      border: 0;

      .border-bottom {
        border-bottom: 0;
        margin-bottom: 20px;
        padding: 0;
        z-index: 100;

        @include mappy-bp(min-width 1024px) {
          border-bottom: 1px solid $border-color;
        }
      }

      .user-menu-count {
        .form-search {
          margin-left: 0;
          display: none;

          @include mappy-bp(min-width 1024px) {
            display: block;
          }
        }

        .user-menu {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
          margin-bottom: 25px;
          font-family: hk_groteskbold;
          font-weight: normal;

          .user-cart {
            font-size: 10px;

            @include mappy-bp(min-width 1000px) {
              font-size: 15px;
            }

            div {
              a {
                background: $mako;
                color: $black;
                line-height: 30px;
                display: inline-block;

                .fa {
                  color: $orange;

                }

                .fa-caret-down {
                  margin-left: 10px;
                  display: inline-block;
                }

                .fa-shopping-cart {
                  margin-right: 5px;
                  display: inline-block;

                  @include mappy-bp(min-width 1000px) {
                    font-size: 15px;
                  }
                }
              }

              div {
                a {
                  color: $green;
                }
              }
            }
          }
        }
      }

      .nav-global {
        padding: 0;

        &>.nav {
          width: 100vw;
          padding-right: 20px;
          padding-left: 11px;

          @include mappy-bp(min-width 1024px) {
            width: 100%;
            padding-right: 0;
            padding-left: 0;
          }

          @include mappy-bp(max-width 1024px) {
            display: none;
          }

          &>li {
            padding: 0 9px;
            border: 1px solid transparent;

            &:last-child {
              padding-right: 0;
              float: right;
              border: 0;

              &>div>a {
                padding: 0 15px;
                color: inherit;
                background: inherit;
              }
            }
          }

          .dropdown:hover {
            background-color: transparent;
            border: 1px solid $border-color;

            &:after {
              content: '';
              position: absolute;
              top: 100%;
              height: 1px;
              width: 100%;
              background-color: $white;
              left: 0;
              z-index: 1001;
            }

            .dropdown-menu {
              display: block;
              padding: 10px 15px;
              border-color: $border-color;
              box-shadow: none;
              left: -1px;
              min-width: calc(100% + 2px);

              &>li>a {
                color: $brown;
                font-family: noto_serifregular;
                text-transform: none;
                font-size: 11px;
                line-height: 25px;
                border-left: 1px solid $border-color-light;
                padding-left: 10px;
                padding-right: 5px;

                &:hover,
                &:focus {
                  background-color: transparent;
                  background-color: $brown;
                  color: $white;

                }
              }
            }
          }

          a {
            font-family: hk_groteskbold;
            font-weight: normal;
            text-transform: uppercase;
            padding: 0;
            line-height: 34px;
            color: $green;
            font-size: 10px;

            @include mappy-bp(1024px 1200px) {
              font-size: 12px;
            }

            @include mappy-bp(min-width 1200px) {
              font-size: 15px;
            }
          }
        }

        form {
          input[type="search"] {
            max-width: 253px;
            float: left;
            border: 1px solid $border-input;
            @extend .no-border-radius;
            @extend .no-shadow;

            &:focus {
              @extend .no-shadow;
            }
          }

          button[type="submit"] {
            float: left;
            border: 0;
            @extend .no-border-radius;
            background: $white;
            line-height: 34px;
            width: 40px;
          }

          .form-group {
            float: left;
            width: auto;
            @extend .no-shadow;
          }
        }
      }
    }

    .navbar-header {
      img {
        margin-top: 0;
        max-width: 196px;
        background-size: cover;
      }
    }
  }

  .form-search {
    float: left;

    button[type=submit] {
      padding: 0;

      @include mappy-bp(max-width 769px) {
        margin-left: 5px;
      }
    }

    .form-action {
      border: 1px solid $athens-gray;

      ul {
        position: absolute;
        max-width: 600px;
        min-width: 400px;
        left: 0;
        margin-top: 45px;
        padding: 0;
        border: 2px solid $green;
        background: $white;
        list-style: none;
        z-index: 1000;

        &:before {
          content: "";
          position: absolute;
          top: -9.3px;
          left: 5px;
          border-left: 2px solid $green;
          border-top: 2px solid $green;
          transform: rotate(45deg);
          width: 15px;
          height: 15px;
          background: $white;
        }

        li {
          div {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 15px 10px;
            border-bottom: 1px solid $athens-gray;
            cursor: pointer;

            strong {
              color: $green;
              font-family: hk_groteskbold;
              font-size: 15px;
            }

            span {
              color: $dark-text;
              font-family: hk_groteskregular;

              &:last-child {
                color: $brown
              }
            }
          }

          &:nth-last-child(2) {
            div {
              border-bottom: none;
            }
          }

          &:last-child {
            div {
              width: 100%;
              height: 50px;
              background: $light-pink;
              display: flex;
              justify-content: center;
              align-items: center;
              border-bottom: none;
            }
          }
        }
      }
    }

    div {
      float: left;
      width: auto;
    }

    input {
      max-width: 130px;
      float: left;
      border: 0;
      padding: 5px 10px;
      height: 100%;
    }

    button {
      border: 0;
      float: left;
      color: $green;
      line-height: 38px;
      background: $white;
    }

    .select-top {
      max-width: 120px;
      float: left;
      position: relative;
      color: $dark-text;
      background: $footer;
      font-size: 12px;

      &:after {
        font-family: FontAwesome;
        content: '\f0dc';
        color: $dark-text;
        position: absolute;
        right: 8px;
        top: 0;
        line-height: 35px;
        z-index: 100;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }

    select {
      position: relative;
      border: 0;
      min-height: 32px;
      background: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      float: left;
      border-radius: 0;
      box-shadow: none;
      min-width: 120px;
      width: 120px;
      z-index: 200;
    }

    select::-ms-expand {
      display: none;
    }
  }

  @include media('<=tablet') {
    .form-search {
      width: 100%;
      margin: 0;

      .form-action {
        ul {
          width: 100%;

          &::before {
            left: 40px;
          }
        }
      }

      form {
        width: 100%;

        div {
          width: 100%;
          display: flex;
        }
      }
    }
  }
}

.checkout #nuherbs {
  div>header {
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.header-inside {
  height: inherit;

  .row {
    height: inherit;

    &>.col-md-2,
    &>.col-md-10 {
      height: inherit;
    }
  }
}

.header-logo {
  display: none;

  @include mappy-bp(min-width 1024px) {
    height: 160px;
    display: flex;
    align-items: center;
  }
}

.hover {
  height: 65px !important;
  transition: all .5s ease-in-out;

  @include mappy-bp(max-width 1024px) {
    height: 50px !important;
    display: none !important;
  }

  .form-search {
    position: absolute;
    transition: all .5s ease-in-out;
    z-index: 300;

    .form-action {
      display: none;
    }

    button {
      margin-left: 0;
    }
  }

  .navbar {
    .border-bottom {
      border-bottom: none !important;
    }

    .header-logo {
      transform: scale(0.5, 0.5) translate(-80px, -60px);
      transition: all .5s ease-in-out;
      height: 130px;

      img {
        width: 70%;
      }
    }

    .navbar-collapse {
      transform: translate(-100px, -87px);
      transition: all .5s ease-in-out;
      z-index: 200;
      margin-top: 20px;

      @include mappy-bp(max-width 1024px) {
        display: none;
      }

      li {
        padding: 0 3px !important;

        a {
          font-size: 9px !important;
          padding: 0 5px;
        }
      }

      &:last-child {
        float: left;
      }
    }

    .nav>li:last-child>div>a {
      padding-left: 10px;
      padding-right: 10px;
    }

    .user-menu-count {
      transform: translateY(-6px);
      transition: all .5s ease-in-out;

      .user-btn {
        span {
          em {
            display: none;
            transition: all .5s ease-in-out;
          }

          a:nth-child(n+1):nth-child(-n+3) {
            display: none !important;
            transition: all .5s ease-in-out;
          }
        }
      }

      .user-cart {
        font-size: 10px !important;

        .cartLink {
          padding-left: 3px;
          padding-right: 3px;
        }
      }
    }
  }

  .Account__shippingDisclaimer__1k3L9 {
    display: none;
  }

  .index__cartLink__3xZq1 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .fa-caret-down {
    margin-left: 3px !important;
  }

  .Account__headerRow__2LHrq a {
    font-size: 10px;
    padding-right: 5px;
    padding-left: 5px;
  }

  .button-for-search {
    display: none;
  }

  .toogleSearchBtn {
    position: absolute;
    top: 8px;
    display: block;
    z-index: 300;
    right: -25px;
  }

  .toogleSearch {
    position: fixed;
    top: 65px;
    width: 100vw;
    left: 0;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid $border-color;

    .form-search .form-action,
    .button-for-search {
      display: block;
    }

    .form-search {
      position: relative;
      display: flex;
      align-items: center;

      .pull-left>div {
        display: flex;
        justify-content: space-between;
        width: 275px !important;
      }
    }
  }
}

.unLogHover {
  height: 65px !important;
  transition: all .5s ease-in-out;

  @include mappy-bp(max-width 1024px) {
    height: 50px !important;
    display: none !important;
  }

  .form-search {
    position: absolute;
    transition: all .5s ease-in-out;
    z-index: 300;

    .form-action {
      display: none;
    }

    button {
      margin-left: 0;
    }
  }

  .navbar {
    .border-bottom {
      border-bottom: none !important;
    }

    .header-logo {
      transform: scale(0.5, 0.5) translate(-9vw, -60px);
      transition: all .5s ease-in-out;
      height: 130px;

      img {
        width: 70%;
      }
    }

    .navbar-collapse {
      position: fixed;
      top: 15px;
      transform: translateX(-10vw);
      transition: all .5s ease-in-out;
      z-index: 200;

      li {
        padding: 0 5px !important;

        a {
          font-size: 10px !important;
          padding: 0 5px;
        }
      }

      &:last-child {
        float: left;
      }
    }

    .user-menu-count {
      transform: translateY(-6px);
      transition: all .5s ease-in-out;

      .user-btn {
        span {
          a:first-child {
            transition: all .5s ease-in-out;
          }
        }
      }

      .user-cart {
        font-size: 10px !important;

        .cartLink {
          padding-left: 3px;
          padding-right: 3px;
        }
      }
    }
  }

  .index__cartLink__3xZq1 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .fa-caret-down {
    margin-left: 3px !important;
  }

  .index__cartLinkContainer__3091B span:nth-of-type(2),
  .index__cartLinkContainer__3091B span:nth-of-type(3) {
    display: none;
  }

  .Account__headerRow__2LHrq a {
    font-size: 10px;
    padding-right: 5px;
    padding-left: 5px;
  }

  .button-for-search {
    display: none;
  }

  .toogleSearchBtn {
    position: absolute;
    top: 8px;
    display: block;
    z-index: 300;
    right: -25px;
  }

  .toogleSearch {
    position: fixed;
    top: 65px;
    width: 100vw;
    left: 0;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid $border-color;

    .form-search .form-action,
    .button-for-search {
      display: block;
    }

    .form-search {
      position: relative;
      display: flex;
      align-items: center;

      .pull-left>div {
        display: flex;
        justify-content: space-between;
        width: 275px !important;
      }
    }
  }
}

.unHover {
  transition: all .3s ease-in-out;

  @include mappy-bp(max-width 1024px) {
    display: none !important;
  }

  .form-search {
    transition: all .5s ease-in-out;

    button {
      margin-left: 10px;
    }
  }

  .navbar {
    .header-logo {
      transition: all .3s ease-in-out;
    }

    .navbar-collapse {
      position: static;
      //values are for transision effect, JM
      top: 100px;
      left: 390px;
      transition: all .3s ease-in-out;
    }

    .user-menu {
      .user-btn {
        opacity: 1;
        transition: all .3s ease-in-out;
      }
    }

    .menuHover {
      transition: all .3s ease-in-out;

      li:nth-child(5) {
        display: block;
        transition: all .3s ease-in-out;
      }
    }
  }
}

.toogleSearchBtn {
  display: none;

  &>button {
    border: none;
    color: $green;
    cursor: pointer;
    background-color: transparent;
  }
}

.toogleSearch {
  display: none;
}

.flash-messages.application_layout {
  position: absolute;
  z-index: 9999;
  width: 100%;
  text-align: center;
  @include media('<=tablet') {
    top: 75px;
  }

  @include media('<=phone') {
    top: 50px;
  }
}
