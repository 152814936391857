@import 'css/vendor/include-media';
@import "~mappy-breakpoints/mappy-breakpoints";

html {
  @include mappy-bp(max-width 768px) {
    overflow-x: hidden;
  }
}

body,
html {
  font-family: noto_serifregular;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: hk_groteskbold;
  font-weight: normal;
}

a,
button,
select,
input,
textarea {
  outline: 0;
}

a:focus,
button:focus,
select:focus,
input:focus,
textarea:focus {
  outline: 0;
}

.fa {
  cursor: pointer !important;
}

.btn {
  border-radius: 0;
  border: 0;
  text-align: center;
  text-transform: uppercase;
  line-height: 42px;
  padding: 0 30px;
  font-family: hk_groteskbold;

  &.btn-primary {
    background: $green;
    color: $white;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($green, 8%);
    }

    &:active {
      background-color: darken($green, 15%);
    }
  }

  &.btn-info {
    background: $white;
    color: $green;
    border: 1px solid $green;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($white, 8%);
    }

    &:active {
      background-color: darken($white, 15%);
    }
  }

  &.btn-link {
    font-weight: normal;
    text-transform: none;

    &:hover {
      text-decoration: none;
    }
  }

  &.btn-thin {
    line-height: 35px;
  }
}

.select {
  @extend .form-control;
  position: relative;
  padding: 0;
  height: 33px;

  select {
    background: none;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 1px;
    text-overflow: '';
    outline: none;
    padding: 6px 12px;
    margin: 0 0;
    box-shadow: none;
    border: 0 none;
    height: auto;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  &:after {
    position: absolute;
    right: 10px;
    top: 7px;
    content: '\F0DC';
    font-family: FontAwesome;
    color: $green;
    pointer-events: none;
  }

  &:focus,
  &:active,
  &:target {
    padding: 0 12px;
  }
}

input,
input:focus {
  outline: none;
}

.container {
  max-width: 1240px !important;
  margin: 0 auto;

  &.container-flashes {
    padding-left: 0;
    padding-right: 0;
    height: 50px;
    position: relative;
    z-index: 0;

    @include mappy-bp(490px) {
      height: 80px;
    }

    @include mappy-bp(min-width 1024px) {
      height: 180px;
    }
  }
}

.container-flashes {
  position: absolute;
  z-index: 120;
  width: 100%;
  text-align: center;
  @include media('<=tablet') {
    top: 75px;
  }

  @include media('<=phone') {
    top: 50px;
  }
}

.containerMainApp {
  @extend .container;

  max-width: 1240px !important;
  width: 100%;
  padding-top: 160px;
  padding-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;

  @include mappy-bp(max-width 1024px) {
    padding-top: 75px;
  }

  @include mappy-bp(max-width 769px) {
    max-width: 769px !important;
  }
}

@include media('<=tablet') {
  .containerMainApp {
    padding-top: 75px;
  }
}

@include media('<=phone') {
  .containerMainApp {
    padding-top: 50px;
  }
}

textarea {
  resize: none;
}

select,
input,
textarea,
.form-control {
  outline: 0 !important;
}

.form-control {
  background-color: transparent;
  box-shadow: none;
}

.container {
  ul {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .well {
    background: none;
    border: 0;
    padding: 0;
  }
}

.green {
  color: $green;
}

.orange_color {
  color: $orange;
}

.breadcrumbs {
  color: $light-dark;
  font: 12px noto_serifregular;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
}

.title--brown {
  font: 37px hk_groteskbold;
  color: $brown;

  @include mappy-bp(max-width 768px) {
    font-size: 25px;
  }
}

.account_no {
  font-size: 20px;
  color: $dark-grey;
}

.button-login-container {
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.btn.btn-login-for-price {
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 12px;

  @include mappy-bp(max-width 769px) {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-size: 10px;
  }

  @include mappy-bp(510px 560px) {
    font-size: 12px;
  }
}

body.home.home-index.modal-open {
  position: fixed;
  width: 100%;
}

.login_form {
  @include mappy-bp(max-width 768px) {
    height: calc(100% - 32px);
    display: flex;
    flex-direction: column;
  }

  label,
  span {
    @include mappy-bp(max-width 768px) {
      font-size: 12px;
      height: inherit;
    }
  }

  form {
    @include mappy-bp(max-width 768px) {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
    }
  }
}

.MobileMenu-container {
  @include mappy-bp(min-width 1024px) {
    display: none;
  }
}

.MobileMenu-container.menu-is-open {
  @include mappy-bp(max-width 1024px) {
    z-index: 100;
    width: 100%;
    height: 100%;
  }
}

.Main-inside {
  @include mappy-bp(max-width 768px) {
    overflow-x: hidden;
  }
}

.Main-inside.menu-is-open {
  @include mappy-bp(max-width 1024px) {
    display: none;
  }
}

main#page.menu-is-open {
  @include mappy-bp(max-width 1024px) {
    display: none;
  }
}

#nuherbs.menu-is-open {
  @include mappy-bp(max-width 1024px) {
    margin-top: -10px;
  }
}

.modal {
  background: rgba(255, 255, 255, 0.2);
}

.legacy-form-container {
  padding: 0 20px 80px;

  @include mappy-bp(max-width 1024px) {
    padding: 0 20px 80px;
  }
}


.static-content {
  padding: 0 20px 80px;

  @include mappy-bp(max-width 1024px) {
    padding: 40px 20px 80px;
  }
}

.user-edit-container {
  padding: 0 20px 80px;

  @include mappy-bp(max-width 1024px) {
    padding: 50px 20px 80px;
  }
}

.block-forms {
  background-color: black;
  opacity: .2;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
}

.radio label, .checkbox label {
  padding-left: 0px;
}
