.state_zip_fields {
  p {
    display: inline-block;
    width: 49.5%;
  }
}

.phone_fields {
  p {
    display: inline-block;
    width: 49.5%;
  }
}
