@import 'css/vendor/include-media';
@import "~mappy-breakpoints/mappy-breakpoints";

#footer {
  clear: both;
  background: $white url(../img/bg-footer.png) 50% 100% no-repeat;
  padding:0 0 35px;
  width:100%;

  .footer_nav {
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;

    &__section {

      ul {
        margin-top: 23px;
        padding: 0;
      }
    }
  }

  .footer-inside {
    position: relative;
    max-width: 1240px;
    margin:0 auto;
    padding:0;
    .NavigationSection-container {
      padding-bottom: 40px;
      h3 {
        margin-bottom: 23px;
        a {
          color: $green;
          font-size:12px;
          text-transform: uppercase;
        }
      }
      li {
        list-style: none;
        padding:0;
        a {
          color:$thunder;
          font-size:12px;
          line-height:22px;
        }
      }
    }
  }

  .footer_text {
    p {
      position: relative;
      color: $dark-text;
      font-size:11px;
      padding: 0 20px;
    }
  }

  .footer_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
    width:100%;
    border-top: 1px solid $border-header;
    padding-top:44px;
    @include mappy-bp(max-width 560px) {
      flex-direction: column;
    }

    .footer-col {
      width: 33.33333%;
      &:first-of-type {
        padding-left: 20px;
      }
      &:last-of-type {
        padding-right: 20px;
      }
      @include mappy-bp(max-width 560px) {
        width: 100vw;
        display: flex;
        justify-content: center;
      }
      @include mappy-bp(340px 560px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .footer-social {
      text-align: right;
    }

    .footer-social-icons {
      display: flex;
      justify-content: flex-end;
      @include mappy-bp(min-width 560px) {
        margin-top: 10px;
      }
    }

    .footer-icon-container {
      margin-left: 10px;
      .fa:before {
        font-size: 20px;
      }
    }

    .footer_img {
      display: flex;
      justify-content: center;

      img {
        max-width: 150px;
      }
    }

    a {
      color: $dark-text;
      display: inline-block;
    }

    .footer_bottom_text {
      width: 35%;
      display: flex;
      justify-content: flex-start;

      &:last-child {
        justify-content: flex-end;

        a {
          padding: 0 5px;
        }
      }
    }

    .social {
      .fa {
        padding-left: 5px;
        font-size: 16px;
      }
    }
  }

  .footer_bottom__links {
    margin-left: 0;
    @include mappy-bp(max-width 560px) {
      margin-left: 10px;
    }
    p {
      margin: 0;
    }
  }

  .no_padding {
    padding-right: 0;
  }
}

.accordion-panel {
  .accordion-panel-heading {
    // border-bottom: 1px solid $border-header;
    padding: 1px 0;

    &:last-child {
      border-bottom: 0;
    }

    h4{
      text-transform: uppercase;
    }

    a {
      color: $green;
      font-size: 16px;
    }
  }

  .accordion-panel-body {
    li {
      list-style: none;
      padding: 0 10px;

      a {
        color: $brown;
        font-size: 12px;
        line-height: 22px;
      }
    }
  }
}

@include media('<=desktop') {
  #footer {
    .footer_nav {
      display: none;
    }

    .row {
      nav {
        padding-bottom: 10px;
      }
    }
  }
}

@include media('<=phone') {
  #footer {
    .row {
      nav {
        padding-bottom: 10px;
      }
    }

    .footer_bottom {
      flex-flow: column wrap-reverse;

      .footer_img {
        width: 100%;
        justify-content: center;
        margin: 20px 0;
      }

      .footer_bottom_text {
        width: 100%;
        text-align: center;
        justify-content: center;

        &:last-child {
          justify-content: center;

          a {
            padding: 0 5px;
          }
        }
      }
    }
  }
}
