@import "~mappy-breakpoints/mappy-breakpoints";

.order-details-page {
  .orderHistoryContainer {
    padding: 0 30px;
  }

  .title--brown.title {
    @include mappy-bp(max-width 490px) {
      margin-top: 25px;
    }
  }

  .header {
    width: 100%;
    padding-bottom: 15px;
    font-family: hk_groteskbold;
    font-size: 32px;
    color: $brown;
  }

  .status {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background: $brown-light;
    padding: 15px 10px;
    margin-top: 15px;

    div {
      display: flex;
      align-items: center;
      flex-flow: column nowrap;

      p {
        font-family: hk_groteskregular;
        color: $black;
        font-size: 15px;
        @include mappy-bp(max-width 465px) {
          font-size: 10px;
        }
        &:first-child {
          font-family: hk_groteskregular;
          font-size: 14px;
          color: $brown;
          text-transform: uppercase;
          @include mappy-bp(max-width 465px) {
            font-size: 10px;
          }
        }
      }
    }
  }

  .address-header-container {
    margin-bottom: 20px;
    @include mappy-bp(min-width 490px) {
      margin-right: 10px;
    }
  }
  
  .address-item-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    @include mappy-bp(max-width 490px) {
      flex-wrap: wrap;
    }
  }

  .address-item h4 {
    @include mappy-bp(max-width 490px) {
      font-size: 12px;
    }
  }

  .addresses {
    padding-top: 30px;
    @include mappy-bp(min-width 1024px) {
      border-top: 1px solid $border-color;
    }
    h4 {
      text-transform: uppercase;
      color: $green;
    }
    p {
      font-family: noto_serifregular;
      font-weight: normal;
      color: $dark;
      span {
        font-family: noto_serifregular;
        font-weight: normal;
        color: $dark;
      }
    }
  }

  .unknown {
    font-family: hk_groteskregular;
    font-size: 15px;
    color: $dark-red !important;
  }

  .address-header {
    margin: 0.3em 0 1.2em;
    color: $green;
    @include mappy-bp(max-width 490px) {
      font-size: 15px;
    }
  }

  .address-entry {
    padding: 0;
    margin: 0;
    line-height: 1.6;
    color: $dark;
    @include mappy-bp(max-width 490px) {
      font-size: 12px;
    }
  }

  .address-entry.shipment {
    @include mappy-bp(max-width 490px) {
      font-size: 12px;
    }
  }

  .address-item {
    @include mappy-bp(max-width 490px) {
      font-size: 12px;
    }
    @include mappy-bp(min-width 490px) {
      margin-right: 15px;
    }
    @include mappy-bp(max-width 1024px) {
      width: 40%;
    }
  }

  .phone {
    padding: 1.5em 0 0.7em;
  }

  .payment-info span {
    @include mappy-bp(max-width 490px) {
      font-size: 12px;
    }
  }

  .line-items {
    overflow-x: scroll;
  }
}
