.YMAL {
  font-family: hk_groteskbold;
  margin: 40px auto 20px;
  border-top: 1px solid $border-color;

  &__header {
    text-align: center;
    font-size: 22px;
    color: $brown;
    margin: 40px auto;
  }
  &__container {
    display: flex;
    justify-content: space-around;
    margin: 0 -10px;
  }

  &__article {
    position: relative;
    width: 290px;
    margin: 0 10px;
    background-color: $brown-light;
  }

  &__image{
    width: 290px;
    height: 230px;
    background-color: $white;

    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__button {
    @extend .btn.btn-primary;
    position: absolute;
    text-transform: uppercase;
    padding: 5px 10px;
    left: 30px;
    transform: translateY(-50%);
  }
  &__title {
    color: $brown;
    padding: 30px;
  }
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.static-page-container {
  max-width: 1050px;
  padding: 0 20px;
  margin-top: 30px !important;
  @include mappy-bp(max-width 490px) {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
  }
}

.static-page--copy {
  font-family: Tahoma;
  font-size: 21px;
  color: #414040;
  @include mappy-bp(max-width 769px) {
    font-size: 15px;
  }
  @include mappy-bp(max-width 490px) {
    font-size: 13px;
  }
}

.static-page--copy.copy-italic {
  font-style: italic;
}

.current-frame {
  border: 10px solid #e6f0ef;
  border-radius: 10px;
  padding: 15px;
  max-width: 300px;
  margin-left: 10px;
  @include mappy-bp(max-width 769px) {
    padding: 7px;
    margin-left: 5px;
  }
  @include mappy-bp(max-width 490px) {
    border-width: 3px;
  }
  img {
    width: 100%;
    height: auto;
    max-width: 75px;
    @include mappy-bp(max-width 769px) {
      max-width: 40px;
    }
    @include mappy-bp(max-width 490px) {
      max-width: 30px;
    }
  }
}

.current-header {
  display: flex;
  align-items: center;
}

.current-header-copy {
  font-family: Tahoma;
  color: #ef662f !important;
  text-decoration: underline;
  font-size: 20px !important;
  font-weight: bold;
  margin-left: 10px;
  @include mappy-bp(max-width 769px) {
    font-size: 13px !important;
  }
  @include mappy-bp(max-width 490px) {
    font-size: 11px !important;
    margin-left: 5px;
  }
}

.current-body-copy {
  font-family: Tahoma;
  color: #50928e;
  font-size: 19px;
  @include mappy-bp(max-width 769px) {
    font-size: 13px;
  }
  @include mappy-bp(max-width 490px) {
    font-size: 11px;
  }
}

h2.static-page--header {
  font-family: Tahoma;
  color: #ef662f;
  text-decoration: underline;
  font-size: 27px;
  font-weight: bold;
  @include mappy-bp(max-width 769px) {
    font-size: 17px;
  }
  @include mappy-bp(max-width 490px) {
    font-size: 15px;
  }
}

.static-page--img {
  width: 100%;
  height: auto;
  max-width: 200px;
  @include mappy-bp(max-width 490px) {
    max-width: 100px;
  }
}

.quality-page-body {
  margin-top: 40px;
}

h3.static-page--subheader {
  font-family: Tahoma;
  color: #50928e;
  font-size: 20px;
  @include mappy-bp(max-width 769px) {
    font-size: 16px;
  }
  @include mappy-bp(max-width 490px) {
    font-size: 14px;
  }
}

.quality-page--subheader {
  display: flex;
  img {
    width: 100%;
    height: auto;
    max-width: 43px;
    max-height: 55px;
    margin-right: 20px;
    @include mappy-bp(max-width 769px) {
      max-width: 30px;
      max-height: 41px;
      margin-right: 10px;
    }
    @include mappy-bp(max-width 490px) {
      max-width: 25px;
      max-height: 34px;
      margin-right: 5px;
    }
  }
}

.quality-page--ul {
  margin-left: 85px;
  margin-bottom: 60px;
  font-family: Tahoma;
  color: #414040;
  font-size: 20px;
  @include mappy-bp(max-width 769px) {
    font-size: 16px;
    margin-left: 50px;
  }
  @include mappy-bp(max-width 490px) {
    font-size: 14px;
    margin-left: 30px;
    padding-left: 0;
  }
}

.modified-padding {
  @include mappy-bp(max-width 769px) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

h2.teapills-header {
  font-family: Tahoma;
  font-size: 26px;
  color: #50928e;
  font-weight: bold;
  margin-top: 30px;
  &:first-of-type {
    margin-top: 0;
  }
}

.teapills--ul {
  font-family: Tahoma;
  font-size: 21px;
  color: #414040;
  margin-left: 20px;
  margin-top: 10px;
  padding-left: 0;
  @include mappy-bp(max-width 769px) {
    font-size: 15px;
  }
  @include mappy-bp(max-width 490px) {
    font-size: 13px;
  }
}

.teapills-disclaimer {
  font-family: Tahoma;
  font-size: 16px;
  color: #414040;
  font-style: italic;
  @include mappy-bp(max-width 769px) {
    font-size: 14px;
  }
  @include mappy-bp(max-width 490px) {
    font-size: 11px;
  }
}

.teapills-frame-container {
  background: #e8f1ea;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 25px;
}

.teapills-frame-col {
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  &:first-of-type {
    border-right: 3px solid #5f9b97;
  }
  h1.current-header-copy {
    text-align: left;
    padding: 0;
    margin: 0;
  }
  li {
    margin-bottom: 10px;
  }
  .static-page--copy.copy-italic {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.teapill-right-col-container {
  width: 100%;
  padding-left: 20px;
  img {
    width: 100%;
    height: auto;
    max-width: 385px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
}

.teapill-right-col {
  font-family: Tahoma;
  font-size: 25px;
  color: #50928e;
  padding-bottom: 50px;
  padding-top: 20px;
  border-top: 20px solid #a17d5a;
  border-bottom: 5px solid #a17d5a;
  @include mappy-bp(max-width 769px) {
    font-size: 20px;
  }
  @include mappy-bp(max-width 490px) {
    font-size: 17px;
  }
}

h2.teapill-right-col-header {
  font-family: Tahoma;
  font-size: 20px;
  font-weight: bold;
  color: #ef662f;
  text-align: center;
  padding: 0;
  margin-top: 5px;
  @include mappy-bp(max-width 490px) {
    font-size: 17px;
  }
  a {
    color: #ef662f;
  }
}

h3.teapills-right-col-discount {
  font-family: Tahoma;
  font-size: 15px;
  color: #50928e;
  text-align: center;
  margin: 0;
  @include mappy-bp(max-width 769px) {
    font-size: 13px;
  }
  @include mappy-bp(max-width 490px) {
    font-size: 11px;
  }
  a {
    color: #50928e;
  }
}

.row.display-flex {
  display: flex;
  @include mappy-bp(max-width 768px) {
    display: block;
  }
}

.col-xs-7.display-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.roots-img {
  width: 100%;
  height: auto;
}

.row.extra-margin {
  margin-top: 20px;
}

.static-page--copy.extra-margin {
  margin-top: 15px;
  margin-bottom: 15px;
}

.static-page--quote {
  font-family: Times Regular;
  font-size: 34px;
  color: #50928e;
  font-style: italic;
  border-top: 20px solid #50928e;
  padding: 10px 20px 30px 10px;
  @include mappy-bp(max-width 490px) {
    font-size: 20px;
  }
}

.static-page--signature {
  font-family: Tahoma;
  font-size: 20px;
  color: #50928e;
  @include mappy-bp(max-width 490px) {
    font-size: 13px;
  }
}

.wilson-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  .roots-img {
    max-width: 250px;
    @include mappy-bp(max-width 490px) {
      max-width: 150px;
    }
  }
}

.img-shadow {
  position: relative;
  left: 35px;
  top: -5px;
}

.link-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  background-color: #FFFAF6;
  margin-top: 30px;
}

.link-shop {
  font-family: hk_groteskbold;
  text-transform: uppercase;
  color: #50928e;
  font-size: 14pt;
}

.join-community {
  font-family: Chalkduster;
  font-size: 27px;
  color: #ef662f;
  text-align: center;
  margin-top: 50px;
  a {
    color: #ef662f;
  }
}

.our-bulk-img {
  width: 30px;
  @include mappy-bp(max-width 1024px) {
    width: 20px;
  }
}

.our-bulk-td {
  padding: 10px 40px;
  @include mappy-bp(max-width 1024px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @include mappy-bp(max-width 330px) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.our-bulk-table {
  th, tr {
    p {
      @include mappy-bp(max-width 1024px) {
        font-size: 14px !important;
      }
      @include mappy-bp(max-width 330px) {
        font-size: 12px !important;
      }
    }
  }
}

.tcm_header {
  font-size:24px;
  padding-top:10px;
  padding-bottom:10px;
  text-align: center;
}

.tcm_margin_left {
  margin-left: 40px;
}

.judges_img_width {
  width: 160px;
}

.tcm_margin_top {
  margin-top: 20px;
}

.tcm_top_bottom_margin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.tcm_bold {
  font-weight: 900;
}

.tcm_poster_annotation {
  max-width:100%;
  padding: 0 5px;
  margin-bottom:20px;
  font-size:12px;
}

.tcm_poster_text {
  font-size: 14px;
  text-align:right;
}

.tcm_image_width {
  max-width:565px;
}

.tcm_full_width {
  width: 100%;
}

.tcm_wrapper {
  max-width:1100px;
  padding:0 20px 0 20px;
}

.tcm_align_center {
  text-align: center;
}

.tcm_sides_margin {
  margin: 0 10%;
}
