.cost-summary {
  background-color: #FFFAF6;
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  .title {
    height: 20%;
    margin-bottom: 10px;

    h4 {
      font-size: 14px;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      color: #000;
      font-family: 'hk_groteskbold';
    }
  }

  .prices {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 7px 0;

      &:last-of-type {
        flex-grow: 999;
        align-items: flex-end;

        span {
         font-family: hk_groteskbold;

         &:last-of-type {
           font-size: 23px;
           color: $green;
          }
        }
      }
      span {
        font: 14px hk_groteskregular;

        &:first-of-type {
          color: $brown
        }
      }
    }
  }
}

.credit_card_info {
  text-align: left;
  font-size: 10px;

  &__underline {
    text-decoration: underline;
  }
}
