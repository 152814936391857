.thank--jumbotron {
  padding: 50px 20px;
  background-color: $light-pink;
  text-align: center;
}

.thank--checkSign {
  &::before {
    content: "\2714";
  }
  color: $green;
  border: 1px solid $green;
  border-radius: 100%;
  font-size: 30px;
  width: 40px;
  height: 40px;
  line-height: 36px;
  display: inline-block;
  margin-right: 10px;
}

.thank--header {
  color: $green;
  line-height: 36px;
}

.thank--description {
  margin: 50px 0px;
}
