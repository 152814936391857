.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  z-index: 9999;

  .spinner:before {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 25px;
    content: '';
    position: absolute;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
  }
}
