$astral: #2b81af;
$athens-gray: #d5d5d5;
$black1: #010101;
$black: #000;
$border-color-light: #f0f0f0;
$border-color: #dadada;
$border-header: #e7e7e7;
$border-input: #d1d1d1;
$border-light: #f3f3f3;
$brand-primary: #1c5c92;
$brown-light: #FFFAF6;
$brown: #a27d57;
$cadet-blue: #619d99;
$calm-green: #5c9996;
$charcoal: #474747;
$dark-grey: #555;
$dark-red: #8b0000;
$dark-text: #989898;
$dark: #585858;
$disabled: #DFDFDF;
$footer: #f8f8f8;
$french-beige: #a37d58;
$gainsboro: #ddd;
$gallery: #f0f0f0;
$green-ligth: #97bcb1;
$green1: #008000;
$green: #50928e;
$green-very-light: #e8f1ea;
$hint-of-red: #f9f9f9;
$iron: #c9c9c9;
$light-dark: #bababa;
$light-pink: #fffaf5;
$light-silver: #ededed;
$light: #cfd1cc;
$mercury: #e6e6e6;
$meriano: #f8efe6;
$milano-red: #a23434;
$mine-shaft: #333333;
$ming: #417673;
$mountain-mist: #929292;
$orange: #ef662f;
$rage-orange: #de7a22;
$dark-orange: #FF8C00;
$red: #ff0000;
$spectra1: #355f5d;
$submarine: #bdc3c7;
$thunder: #4b4b4b;
$warn-red: #991E1E;
$white-lilac1: #E7E7E7;
$white-lilac: #e9e9e9;
$white-smoke1: #e5e5e5;
$white-smoke: #F5F5F5;
$white: #fff;

$bunker1: rgba(0, 0, 0, 0.3);
$bunker: rgba(0, 0, 0, 0.5);
$mako: rgba(255,255,255,0.3);
$spectra: rgba(80,146,142,0.7);
$transparent: rgba(0, 0, 0, 0);
$vulcan: rgba(255, 191, 128, 0.1);




$border-radius-base:        0px !default;
$border-radius-large:       0px !default;
$border-radius-small:       0px !default;

$input-border: #e9e9e9 !default;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

// Override default include-media breakpoints
$breakpoints: (phone: $screen-xs, tablet: $screen-sm, desktop: $screen-md);

.no-border-radius {
  border-radius:0;
}

.no-shadow {
  box-shadow: none;
}

.vm {
  display:flex;
  vertical-align: middle;
  height: 100%;
}
.vm > * {
  display: flex;
  align-items: center;
}
