.breadcrumbs {
  a {
    font-size: 12px;
    font-family: noto_serifregular;
    padding-left: 5px;
    color: $light-dark;
    &:after {
      content: '/';
      padding-left: 4px;
      margin-left: 5px;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      &:after {
        content: ' ';
      }
    }
    &:hover {
      color: $black;
      &:after {
        color: $light-dark;
      }
    }
  }
}

.article-content .breadcrumb,
.container-article .breadcrumb {
  margin-top: 5px;
  @include mappy-bp(767px) {
    margin-top: 0px;
  }
  @include mappy-bp(max-width 768px) {
    padding-left: 5px;
    padding-right: 5px;
  }
}
