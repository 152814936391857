.error-page {
  min-height: calc(100vh - 240px);
  padding: 75px 20px;

  &__500 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__container {
      h1 {
        font-size: 8em;
      }

      p {
        color: $green;
        font-size: 1.4em;
        font-family: hk_groteskregular;
      }
    }
  }

  &__404 {
    display: flex;
    flex-direction: column;

    &__container {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;

      @include mappy-bp(min-width 1024px) {
        flex-direction: row;
      }
    }

    &__col {
      width: 100%;

      @include mappy-bp(min-width 1024px) {
        width: 50%;
        min-height: 650px;
      }

      &--img {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &--content {
        padding: 0 20px;
      }
    }

    &__header {
      text-align: center;

      h1 {
        font-size: 3.8em;
        color: $green;
      }

      h3 {
        font-family: Helvetica, 'OpenSans-Light', Arial, sans-serif;
        font-size: 1.8em;
        font-weight: lighter;
        color: $green;
        line-height: 1.6;
      }
    }

    &__image {
      display: flex;
      justify-content: center;
      margin: 36px 0;

      img {
        width: 95px;
        height: 98px;
        object-fit: contain;
      }
    }

    &__form {
      position: relative;
      display: flex;
      justify-content: center;

      button {
        margin-left: 10px;
      }

      ul {
        max-width: 320px !important;
        min-width: 320px !important;
        left: 42px !important;
        right: 0;
        margin: 45px auto;
      }
    }

    &__links {
      margin: 36px 0;

      p {
        font-family: Helvetica, 'OpenSans-Light', Arial, sans-serif;
        font-size: 1.4em;
        font-weight: lighter;
        color: $green;
        text-align: center;
      }

      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
      }

      li {
        margin: 12px 0;

        a {
          color: $orange;
          font-size: 1.2em;
          font-weight: bold;
          font-family: Helvetica, 'OpenSans-Light', Arial, sans-serif;
        }
      }
    }
  }
}
