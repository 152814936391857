.order-totals {
  margin: 25px 0 30px;
  padding-left: 10px;
  padding-right: 10px;

  .order-totals-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .left,
    .right {
      font-family: hk_groteskregular;
      text-transform: uppercase;
      font-size: 16px;
    }

    .left {
      color: $brown;
    }

    .right {
      color: $black;
      text-align: right;
    }
  }

  .total {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right {
      h3 {
        margin: 0;
        color: $green;
      }
    }
  }
}
