.sort {
  color: $brown;
  span {
    font-weight: normal;
  }
  .form-group {
    padding-top:5px;
    float: left;
  }
  select {
    border-radius:0;
    border:1px solid $border-header;
  }
}
