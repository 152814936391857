.modal-dialog {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mappy-bp(max-width 330px) {
    height: 100%;
    padding-top: 15%;
    padding-bottom: 15%;
  }
}

.login-modal {
  @include mappy-bp(max-width 767px) {
    top: 15px;
  }

  .modal-dialog {
    @include mappy-bp(max-width 767px) {
      height: 100%;
    }
  }

  .modal-content {
    @include mappy-bp(max-width 330px) {
      height: inherit;
    }
  }
}

.modal-body {
  @include mappy-bp(max-width 768px) {
    height: 100%;
    overflow-y: auto;
  }

  @include mappy-bp(max-width 330px) {
    padding: 5px;
  }
}

.modal-backdrop {
  display: none;
}

.modal-content {
  border: 30px solid $spectra;
  border-radius: 0;
  box-shadow: none;

  @include mappy-bp(max-width 768px) {
    height: inherit;
    border-width: 15px;
  }

  @include mappy-bp(max-width 767px) {
    height: 80%;
    width: 90%;
    border-width: 10px;
  }

  @include mappy-bp(max-width 330px) {
    width: 90%;
    border-width: 5px;
    top: 15px;
  }

  img {
    width: 100%;
  }

  a {
    color: $green;
  }

  .table_info {
    margin: 15px 0;
    color: $dark;
  }

  .modal-header {
    padding: 0;

    .close {
      position: absolute;
      right: -20px;
      top: -20px;
      color: $green;
      background: $white;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      line-height: 18px;
      opacity: 1;
      z-index: 9999999;

      @include mappy-bp(max-width 767px) {
        right: 0;
        top: 5px;
        width: 25px;
        height: 25px;
      }

      span {
        display: block;
        line-height: 16px;
        height: 20px;
        float: left;
        width: 100%;

        @include mappy-bp(max-width 767px) {
          height: 25px;
          font-size: 30px;
        }
      }
    }
  }
}

.QuickViewModal {
  @include mappy-bp(max-width 767px) {
    top: 15px;
  }
}

.QuickView-container {
  height: inherit;

  .container-fluid {
    height: inherit;
  }
}

.QuickView-inside {
  height: inherit;

  @include mappy-bp(max-width 767px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .btn.btn-info {
    @include mappy-bp(max-width 769px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include mappy-bp(max-width 330px) {
      font-size: 10px;
      line-height: 30px;
    }
  }
}
