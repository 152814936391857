@import 'css/vendor/include-media';

.product-list-item {
  .notInCart {
    opacity: 0.4;
  }

  &:hover .notInCart {
    opacity: 1;
  }
}

.sort {
  border: 1px solid #dadada;
  display: flex;
  justify-content: center;
  height: 43px;
}

.sort,
.view {
  padding: 0;
  margin-bottom: 25px;
  width: 100%;

  @include mappy-bp(max-width 1200px) {
    height: 50px;
  }

  @include mappy-bp(max-width 490px) {
    margin-bottom: 15px;
  }

  label {
    float: left;
    margin-right: 0;
    font-family: hk_groteskregular;
    text-transform: uppercase;
    margin-bottom: 0;
    width: 100%;
    max-width: 60px;

    @include mappy-bp(max-width 490px) {
      line-height: 1;
      margin-left: 5px;
      display: flex;
      align-items: center;
    }
  }

  select {
    float: left;
    height: inherit;

    @include mappy-bp(max-width 490px) {
      width: 90px;
      padding: 0;
      font-size: 10px;
    }
  }

  .form-group {
    height: inherit;
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    span {
      @include mappy-bp(max-width 490px) {
        font-size: 10px;
      }
    }
  }
}

.select-sort-container {
  padding-right: 0;

  label {
    margin-left: 15px;
  }
}

.select-view-container {
  padding-left: 0;
}

.view {
  display: flex;
  justify-content: center;
  border: 1px solid $border-color;
  border-left: 0;
}

.ViewAs__viewAs__E2Ku9 {
  padding-top: 0 !important;
  display: flex;
  align-items: center;
}

.view-as-container {
  height: 43px;
  display: flex;
}

.AlphabeticalPagination__filters__Vy-U1 {
  display: flex;
  justify-content: center;
}

.ViewAs__select__1SfGk {
  width: 100%;
  display: flex;
  justify-content: center;
}

@include media('<=tablet') {
  .sort {
    border: 1px solid $border-color;
    display: flex;
    justify-content: center;

    .form-group {
      position: relative;
      margin-bottom: 0;

      &::before {
        content: "\F0D7";
        position: absolute;
        font-family: FontAwesome;
        font-size: 12px;
        right: 0;
        height: 100%;
        padding-bottom: 8px;
        display: none;
        align-items: center;
        color: $green;
      }

      span {
        font-family: hk_groteskregular !important;
        font-weight: bold;
      }

      select,
      select:active {
        outline: none;
        border: 0;
        border-color: transparent;
        outline: none;
        box-shadow: none;
      }
    }
  }
}

@include media('<=phone') {
  .view {
    margin-bottom: 20px;
  }

  .sort {
    .form-group {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;

      &::before {
        right: 10%;
      }

      label {
        margin-right: 4px;
      }

      select {
        &::before {
          right: 20%;
        }
      }
    }
  }
}
