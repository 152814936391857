@import "~mappy-breakpoints/mappy-breakpoints";

.page-title {
  margin-top: 0;
  margin-bottom: 25px;
}

.form-body {
  width: 100%;
  background-color: $brown-light;
  padding: 30px 30px 70px;

  input:not([type="submit"]),
  textarea {
    line-height: 2.4em;
    width: 100%;
    padding-left: 1em;
    color: $light-dark;
    font-family: hk_groteskregular;
    border: 1px solid $green-ligth;
    margin-bottom: 15px;
  }

  select {
    height: 2.7em;
    line-height: 2.4em;
    width: 100%;
    padding-left: 1em;
    font-family: hk_groteskregular;
    border: 1px solid $green-ligth;
    margin-bottom: 15px;
  }

  .address-container {
    display: flex;
    flex-flow: column nowrap;

    .address-title {
      font-family: hk_groteskregular;
      font-size: 28px;
      color: $green;
      margin: 25px 0 15px;

      i {
        height: 100%;
        vertical-align: text-bottom;
        font-size: 32px;
      }
    }

    span {
      margin-bottom: 5px;
    }
  }

  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;

    .form-title {
      font-family: hk_groteskbold;
      color: $thunder;
      margin: 25px 0;

      h1 {
        font-size: 24px;
      }
    }

    form {
      width: 50%;
      display: flex;
      flex-flow: column nowrap;
    }

    .form-name, .form-email, .form-description {
      color: $black;
    }
  }

  .form-submit {
    border: 1px solid $green;
    text-transform: uppercase;
    background: transparent;
    padding: 14px 10px 10px;
    text-align: center;
    color: $green;
    font-family: hk_groteskregular;

    &:hover {
      background-color: $green;
      color: $white;
    }

    &:active {
      background-color: darken($green, 30%);
      border-color: darken($green, 30%);
      color: $white;
    }
  }
}

.card-container {
  position: relative;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: space-around;
  flex-flow: row nowrap;
  margin-bottom: 50px;
  @include mappy-bp(max-width 1024px) {
    margin-bottom: 20px;
  }

  .background-line {
    position: absolute;
    width: 100%;
    height: 50px;
    background-color: $brown-light;
    z-index: 1;
  }

  .card-item {
    position: relative;
    width: 45%;
    background-color: $white;
    border: 1px solid $light-dark;
    z-index: 10;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 20px;
    @include mappy-bp(max-width 490px) {
      width: initial;
      flex: 1;
      border-right: 0;
    }

    a {
      text-align: center;
      height: 100%;

      .card-title {
        font-family: noto_serifregular;
        font-size: 14px;
        color: $thunder;
      }

      i {
        font-size: 42px;
        color: $green;
        margin: 20px 0;
      }

      .card-content {
        color: $brown;
        font-size: 16px;
        font-family: noto_serifregular;
        @include mappy-bp(max-width 490px) {
          font-size: 13px;
        }
      }
    }

    &:hover {
      background-color: $green-ligth;
      border: 1px solid $green-ligth;

      .fa,
      .card-title,
      .card-content {
        color: $white;
      }
    }

    &:active {
      background-color: darken($green-ligth, 30%);
      border-color: darken($green-ligth, 30%);

      .fa,
      .card-title,
      .card-content {
        color: $white;
      }
    }
  }
}
