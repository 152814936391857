@import '~font-awesome/scss/font-awesome';

@font-face {
  font-family: 'hk_groteskbold';
  src: url('../fonts/hkgrotesk-bold-webfont.eot');
  src: url('../fonts/hkgrotesk-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/hkgrotesk-bold-webfont.woff') format('woff'),
       url('../fonts/hkgrotesk-bold-webfont.ttf') format('truetype'),
       url('../fonts/hkgrotesk-bold-webfont.svg#hk_groteskbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'hk_groteskregular';
  src: url('../fonts/HKGrotesk-Regular.eot');
  src: url('../fonts/HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HKGrotesk-Regular.woff') format('woff'),
  url('../fonts/HKGrotesk-Regular.ttf') format('truetype'),
  url('../fonts/HKGrotesk-Regular.svg#hk_groteskregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: 'noto_serifregular';
    src: url('notoserif-regular-webfont.eot');
    src: url('notoserif-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('notoserif-regular-webfont.woff') format('woff'),
         url('notoserif-regular-webfont.ttf') format('truetype'),
         url('notoserif-regular-webfont.svg#noto_serifregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'noto_serifbold';
    src: url('notoserif-bold-webfont.eot');
    src: url('notoserif-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('notoserif-bold-webfont.woff') format('woff'),
         url('notoserif-bold-webfont.ttf') format('truetype'),
         url('notoserif-bold-webfont.svg#noto_serifbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'noto_serifitalic';
    src: url('notoserif-italic-webfont.eot');
    src: url('notoserif-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('notoserif-italic-webfont.woff') format('woff'),
         url('notoserif-italic-webfont.ttf') format('truetype'),
         url('notoserif-italic-webfont.svg#noto_serifitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Chalkduster';
    src:url('Chalkduster.woff') format('woff'),
        url('Chalkduster.svg#Chalkduster') format('svg'),
        url('Chalkduster.eot'),
        url('Chalkduster.ttf'),
        url('Chalkduster.eot?#iefix') format('embedded-opentype'); 
    font-weight: normal;
    font-style: normal;
}
