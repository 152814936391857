@import "~mappy-breakpoints/mappy-breakpoints";

.article-template {
  font-family: 'noto_serifregular', sans-serif;
  font-size: 16px;
  text-align: justify !important;
  padding-left: 30px;
  padding-right: 30px;
  @include mappy-bp(max-width 769px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &#template-with-both-bars .article-content {
    padding-right: 30px;
  }

  .break-space {
    margin-bottom: 35px;
  }

  h1.top-h1 {
    font-family: 'hk_groteskbold', sans-serif;
    font-size: 2.5em;
    color: $brown;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .breadcrumb {
    background: none;
    font-size: 0.85em;
    font-family: 'noto_serifregular', serif;
    margin-bottom: 0;
    padding-left: 0;

    a, a:visited {
      color: $mountain-mist;

      &:hover {
        color: darken($mountain-mist, 15%);
      }
    }
  }

  .cardContainer {
    background: $white;
    padding: 0 15px;
    .quote {
      padding-top: 25px;
      blockquote {
        padding: 10px 20px 0px 20px;
        margin: 0;
      }
    }
    .thumbnail {
      padding: 0;
      div {
        padding: 0;
      }
      span {
        background: $green;
        text-transform: uppercase;
        position: absolute;
        padding: 5px 10px;
        top: -1em;
        left: 1em;
        color: $white;
        font-size: 12px;
      }
      .caption {
        width: 100%;
        height: 80px;
        background: $vulcan;
        p {
          padding: 20px 15px;
          color: $brown;
          font-family: hk_groteskbold;
          font-size: 20px;
        }
      }
    }
  }

  .text-building-block {
    padding: 10px 20px 0;

    p {
      font-size: 16px;
      font-style: italic;

      &::before {
        content: '\0275D';
        font-size: 18px;
        font-family: hk_groteskregular;
        font-weight: lighter;
        color: $black;
        margin-right: -1px;
      }

      &::after {
        content: '\0275E';
        font-size: 18px;
        font-family: hk_groteskregular;
        font-weight: lighter;
        color: $black;
      }
    }
  }

  .share-this-icons {
    a, a:hover, a:active, a:visited {
      text-decoration: none;
    }

    span {
      font-size: 25px;
      color: $green;
      margin-right: 5px;
      &.share-text {
        font-size: 0.85em;
        color: $brown;
        font-family: hk_groteskregular;
        vertical-align: text-top;
      }

      &::before {
        font-family: FontAwesome !important;
      }
    }
  }

  .tag-name {
    color: $brown;
    font-size: 0.85em;
    font-family: 'noto_serifbold', serif;
    margin-top: 10px !important;
    &:after {
      content: '•';
      color: $black;
      padding: 0 0.4em;
    }

    &:last-child:after {
      content: '';
    }
  }

  blockquote {
    border-left: 8px solid $brown;

    a, a:hover, a:visited, a:active {
      color: $black;
      text-decoration: none;
      font-style: italic;
      font-family: 'noto_serifitalic', serif;
      font-size: 1.2em;
    }

    cite {
      color: $green;
      font-style: normal;
      font-family: sans-serif;
    }
  }

  .thumbnail {
    border: 0;
    background-color: $transparent;
    overflow: hidden;

    img:hover {
      opacity: 0.9;
    }
  }

  #bb-shop-online {
    background-color: $vulcan;
    padding: 3em;

    a, a:visited {
      color: $green;
      text-decoration: none;

      &:hover {
        color: darken($green, 10%);
        text-decoration: underline;
      }

      &:active {
        color: darken($green, 20%);
      }
    }
  }

  #bb-newsletter {
    color: $brown;
    padding: 1em 2em;
    border: 1px solid $meriano;

    hr {
      display: none;
    }

    h6 {
      font-family: 'hk_groteskbold', sans-serif;
      font-size: 32px;
    }

    p {
      font-family: 'noto_serifregular', serif;
      font-size: 19px;
    }

    form {
      margin: 40px 0;
      font-size: 1.1em;

      input {
        line-height: 2em;

        &[type='email'] {
          width: 82%;
          padding-left: 1em;
          color: $black;
        }

        &[type='submit'] {
          width: 16%;
          border: 0;
          background-color: $green;
          color: $white;
          padding-top: 3px;
          padding-bottom: 3px;

          &:hover {
            background-color: darken($green, 8%);
          }

          &:active {
            background-color: darken($green, 15%);
          }
        }
      }
    }
  }

  #bb-related-articles {
    h6 {
      color: $brown;
      font-size: 24px;
      padding-left: 0.9em;
      margin-bottom: 1.5em;
    }

    ul {
      list-style-type: disc;

      li {
        font-family: 'hk_groteskbold', sans-serif;
        font-size: 18px;
        line-height: 1.8em;
        padding-left: 0.5em;

        a {
          color: $green;
        }
      }
    }
  }

  #bb-you-may-also-like {
    h6 {
      color: $brown;
      font-size: 20px;
      margin-bottom: 2em;
    }

    .related-article-box {
      padding: 0 7px;
      background-color: $vulcan;

      .top-tile-image {
        padding: 0 5px;
        height: 200px;
        background: no-repeat center;
        background-color: inherit;
        background-size: cover;
      }

      .related-article-content {
        padding: 0 25px 5px 25px;

        .category-label {
          color: $white;
          font-size: 12px;
          text-transform: uppercase;
          padding: 8px 15px;
          position: relative;
          top: -1.1em;
          background-color: $green;
        }

        p {
          padding-top: 7px;
          color: $brown;
          font-size: 22px;
        }
      }
    }
  }

  #bb-social-media {
    h6 {
      color: $brown;
      font-size: 16px;
    }

    a, a:visited {
      color: $green;
      margin: 0 8px;
      text-decoration: none;

      &:hover {
        color: darken($green, 8%);
      }

      &:active {
        color: darken($green, 15%);
      }
    }
  }

  .br-divider {
    display: block;
  }

  .hr-divider {
    display: none;
  }

  .side-bar-column {
    background: $vulcan;

    .mediaContainer {
      padding: 20px 40px 30px 40px;
      border-bottom: 1px solid $border-color;
      display: flex;
      justify-content: center;
      .title {
        font-family: hk_groteskregular;
        font-size: 18px;
        color: $brown;
        text-align: center;
      }
      .icon-container {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        .fa {
          &:before {
            font-size: 45px;
            color: $green;
            font-family: FontAwesome !important;
            @include mappy-bp(769px 1200px) {
              font-size: 30px;
            }
          }
        }
        a {
          margin-left: 25px;
          &:first-child {
            margin-left: -7px;
          }
        }
      }
    }

    blockquote {
      border-left: 5px solid $brown;

      a, a:hover, a:visited, a:active {
        font-size: 1em;
      }

      cite {
        font-size: 0.95em;
      }
    }

    .thumbnail {
      font-size: 0.9em;

      &>div {
        width: 100%;
      }
    }

    #bb-shop-online {
      background-color: $transparent;
    }

    #bb-related-articles {
      h6 {
        color: $brown;
        font-size: 24px;
        padding-left: 0.9em;
        margin-bottom: 1.5em;
      }

      ul {
        list-style-type: disc;

        li {
          font-family: 'hk_groteskbold', sans-serif;
          font-size: 18px;
          line-height: 1.8em;
          padding-left: 0.5em;

          a {
            color: $green;
          }
        }
      }
    }

    #bb-you-may-also-like {
      .related-article-box {
        padding-left: 0;
        padding-right: 0;
        display: block;
        width: 100%;
        background-color: $transparent;
        margin-bottom: 25px;
      }
    }

    #bb-newsletter {
      padding: 1em 2em;
      border: 0;
      box-shadow: 0;

      .newsletter-column {
        display: block;
        width: 100%;
        padding: 0;
      }

      h6 {
        font-family: 'hk_groteskbold', sans-serif;
        font-size: 20px;
      }

      p {
        color: $black;
        font-family: 'noto_serifregular', serif;
        font-size: 18px;
      }

      form {
        margin-top: 10px;

        input {
          line-height: 2em;

          &[type='email'] {
            width: 100%;
          }

          &[type='submit'] {
            width: 35%;
            margin-top: 10px;
            float: right;
          }
        }
      }
    }

    .br-divider {
      display: none;
    }

    .hr-divider {
      display: block;
    }
  }

  img {
    width: 100% !important;
    height: auto !important;
    padding-top: 20px;
  }

  ul li {
    padding: 0.4em 0em 0.4em;
  }

  ol li {
    padding: 0.4em 0em 0.4em;
  }

  a, a:hover, a:visited, a:active {
    font-weight: bold;
    color: $dark-orange;
    text-decoration: underline;
    text-decoration-color: black;
  }
}

.article-inside {
  padding-left: 20px;
  padding-right: 20px;
}

.container-article {
  max-width: 1240px !important;
  color: $dark;
  font-size: 16px;
  padding: 0;
  margin: auto;

  #wrapper {
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  h1,h2,h3,h4,h5,h6 {
    color: $brown;
    padding-bottom: 10px;
  }

  h1 {
    padding: 0 10px 10px;
    margin-top: 0;
    font-size: 40px;
    text-align: center;
    font-family: Tahoma;
    font-weight: 600;
    @include mappy-bp(max-width 769px) {
      margin-top: 35px;
    }
    @include mappy-bp(max-width 769px) {
      font-size: 30px;
    }
    @include mappy-bp(max-width 490px) {
      font-size: 25px;
    }
  }

  h3 {
    font-size: 24px;
  }

  .page-tahoma-font {
    h1,h2,h3,h4,h5,h6 {
      font-family: Tahoma;
      font-weight: bold;
    }
    p,li {
      font-family: Tahoma;
      font-size: 18px;
    }
    .bold {
      font-weight: bold;
    }
    .italic {
      font-style: italic;
    }
  }

  .table {
    border: 1px solid $light;
    text-align: center;
    width: 1000px;
    margin: 0 auto;
    padding: 20px 0 0;
    .tableContent {
      vertical-align: top;
      padding: 25px;
    }
    .tableTitle {
      font-size: 22px;
      color: $brown;
      min-height: 62px;
    }
  }

  .greenTable {
    background-color: $green-very-light;
    text-align: center;
    width: 100%;
    margin: auto;
    .greenTableContent {
      vertical-align: top;
    }
    .greenTableTitle {
      font-size: 22px;
      color: $green;
      margin-left: 20px;
    }
  }

  .static-list {
    .static-list__item {
      font-family: Tahoma;
      font-size: 18px;
    }
  }

  span {
    font-family: inherit;
    font-size: inherit;
  }

  .green {
    color: $green;
  }
  .brown {
    color: $brown;
  }
  .orange {
    color: $orange;
  }
  .bold {
    font-family: noto_serifbold;
  }
  .italic {
    font-family: noto_serifitalic;
  }
  .notoSerif {
    font-family: noto_serif;
  }

  .hkGrotesk {
    font-family: hk_groteskregular;
  }

  .center {
    text-align: center;
  }
  .right {
    float: right;
  }
  .left {
    float: left;
  }

  @include media('<desktop') {
    .whyNuherbsImage {
      width: 100%;
      max-width: 505px;
      margin-left: 0 !important;
    }
    .nuherbsTcmScholarchipsImage {
      width: 100%;
    }
    .nuherbsTcmScholarchipsDescription {
      margin-left: 0 !important;
      width: unset !important;
      max-width: 610px;
    }
    .nuherbsGeoAuthenticHerbsImage {
      width: 100%;
    }
    .nuherbsGeoAuthenticHerbsDescription {
      margin-left: 0 !important;
      width: unset !important;
      max-width: 295px;
    }
    .nuherbsadeDragonClassicalFormulasImage {
      width: 100%;
    }
    .nuherbsJadeDragonClassicalFormulasDescription {
      margin-left: 0 !important;
      width: unset !important;
      max-width: 234px;
    }
    .centerImage {
      margin: 0 auto !important;
    }
    .removePaddingLeft {
      padding-left: 0 !important;
    }
  }
}
