.payment-information {
  @include mappy-bp(min-width 1024px) {
    padding-top: 25px;
    border-top: 1px solid $border-color;
  }

  .radio-titel {
    p {
      font-size: 16px;
      font-family: hk_groteskregular;
      font-weight: bold;
      color: $green;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 15px;
    }
  }

  .radio-ctrl[type="radio"].radio-ctrl[type="radio"] {
    background-color: $white;
    border-radius: 15px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    margin: 0 15px 0 0;
    width: 20px;
    -webkit-appearance: none;
    border: 1px solid $green;
  }

  .radio-ctrl[type="radio"]:checked::after {
    background-color: $green;
    border-radius: 90px;
    content: "";
    display: block;
    height: 8px;
    left: 5px;
    position: relative;
    top: 5px;
    width: 8px;
  }

  .radio-label {
    cursor: pointer;
    margin: 0;
    width: calc(100% - 35px);
  }

  .radio-container {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 15px;
    border-bottom: 1px solid $border-color;
  }

  .radio-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 10px;
  }

  .radio-payment-method, .radio-item {
    input {
      flex-shrink: 0;
    }

    input[type=radio] + label {
      margin-left: 0px;
      margin-bottom: 2px;
    }

    input[type=radio] + label::before {
      content: none;
    }

    label {
      width: auto;
      flex-shrink: 0;
      margin-right: 20px;
    }

    .payment-method-description {
      flex-shrink: 1;
    }
  }

  .payment-methods {
    padding-top: 25px;
    display: flex;
    flex-flow: row nowrap;

    .payment-methods-options {
      width: 50%;
      display: flex;
      flex-flow: column nowrap;

      @include mappy-bp(max-width 992px) {
        font-size: 11px;
      }

      @include mappy-bp(max-width 400px) {
        width: 100%;
      }
    }

    .new-braintree-payment-method {
      width: 50%;
      margin-left: 15px;

      label {
        color: $green;

        @include mappy-bp(max-width 992px) {
          font-size: 11px;
        }
      }

      .fields-part {
        display: flex;
        flex-direction: column;

        @include mappy-bp(max-width 992px) {
          font-size: 11px;
        }
      }

      .fields-detail {
        width: 100%;
      }
    }
  }

  hr {
    margin-bottom: 5px;
  }
}

.save-payment {
  a {
    color: $brown;
    font-size: 16px;
    font-family: hk_groteskregular;
    text-decoration: underline;
  }
}

.coupon-code {
  margin: 0 0 25px;

  label {
    font-size: 16px;
    font-family: hk_groteskregular;
    color: $green;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 15px;
  }
}

.summary-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #fffaf6;
  margin-bottom: 30px;
}

.line-items-container {
  table {
    margin: 0;
  }
}

.address-item {
  margin-top: 0 !important;
  margin-bottom: 40px;

  h4 {
    margin-top: 5px;
  }
}
