.lineItemsTable {
  margin: 40px 0px;
  border: 0 !important;
  width: 100% !important;

  .tableHeader.tableHeader {
    background-color: $light-pink;
    font-family: hk_groteskbold;
    font-size: 12px;
    color: $brown;
    text-transform: uppercase;
    border: 0;
  }

  .tableRow.tableRow {
    th, td {
      padding: 30px 5px !important;
      font-family: hk_groteskregular;
      vertical-align: middle;
      border-bottom: 1px solid $border-color;
      border-color: $border-color !important;
      @include mappy-bp(max-width 490px) {
       padding-left: 3px !important;
       padding-right: 3px !important;
      }
    }
  }

  .idColumn {
    color: $dark-text;
    @include mappy-bp(max-width 490px) {
      font-size: 12px;
    }
  }

  .taxonName {
    color: $brown;
  }

  .productName {
    color: $green;
    font-size: 1.3em;
    font-weight: bold;
    @include mappy-bp(max-width 490px) {
      font-size: 12px;
    }
  }

  .chineseProductName {
    color: $dark-text;
    font-size: 12px;
  }

  .unitSizeColumn {
    font-size: 14px;
    color: $dark-text;
    @include mappy-bp(max-width 490px) {
      font-size: 12px;
    }
  }

  .unitPriceColumn {
    font-size: 17px;
    @include mappy-bp(max-width 490px) {
      font-size: 12px;
    }
  }

  .quantityColumn {
    font-size: 1.2em;
    color: $dark-text;
    @include mappy-bp(max-width 490px) {
      font-size: 12px;
    }
  }

  .subtotalColumn {
    font-size: 23px;
    @include mappy-bp(max-width 490px) {
      font-size: 14px;
    }
  }

  .invoicePriceColumn {
    font-size: 17px;
  }
}

.line-items-container {
  overflow-x: scroll;
}

tr.tableRow:first-of-type,
tr.tableRow:last-of-type {
  td, td {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
}

.tableRow.tableRow th {
  border-bottom: 0 !important;
  @include mappy-bp(max-width 490px) {
    font-size: 8px;
  }
}

.tableRow.tableRow th:first-of-type,
.tableRow.tableRow td:first-of-type {
  padding-left: 22px !important;
  @include mappy-bp(max-width 1024px) {
    padding-left: 10px !important;
  }
  @include mappy-bp(max-width 769px) {
    padding-left: 3px !important;
  }
}
