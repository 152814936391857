@import 'css/vendor/include-media';

.account-page {
  @include mappy-bp(414px 489px) {
    padding-top: 25px;
  }
  .separatorPadd {
    display: flex;
    flex-flow: row nowrap;
  }

  .title {
    padding: 0 15px;

    h3 {
      margin: 8px 0 0;
      text-transform: capitalize;
    }
  }

  .billHeader {
    margin: 30px 0 1.2em;
    color: $green !important;
    font-family: hk_groteskregular;
    font-weight: bold;
    text-transform: uppercase;
  }

  .addAddress {
    @extend .billHeader;
    font-size: 18px;

    &::before {
      color: $green;
      font-family: FontAwesome;
      padding-right: 10px;
    }
  }

  .addressEntry {
    padding: 0;
    margin: 0;
    line-height: 1.6;
    color: $dark;
    font-family: noto_serifregular;
    font-size: 16px;
  }

  .phone {
    padding: 34px 0 20px;
  }

  .change-adddress {
    font-size: 0.8em;
    letter-spacing: 0.5px;
    text-decoration: underline;
    color: $brown;
  }

  .addressEntry {
    padding: 0;
    margin: 0;
    line-height: 1.6;
    color: $dark;
  }

  .emailCell {
    padding: 0 0 1.8em
  }

  .email {
    padding: 0 0 0.6em;
    margin: 0;
    line-height: 1.6;
    color: $dark;
  }

  .editEmail {
    font-size: 1.1em;
    letter-spacing: 0.5px;
    text-decoration: underline;
    color: $brown;
  }
  .pass {
    font-size: 1.1em;
    letter-spacing: 0.5px;
    text-decoration: underline;
    color: $brown;
  }
  .separatorPadd { padding: 0 0 1.5em }

  .ship-address-container {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 25px;

    .circle-count {
      width: 30px;
      height: 30px;
      border-radius: 45px;
      border: 1px solid $green;
      color: $green;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      font-family: noto_serifregular;
    }

    .action-button a {
      color: $brown;
      font-size: 1.1em;
      text-decoration: underline !important;
    }
  }

  /* TABLE STYLES */

  .order-container {
    margin-bottom: 100px;
  }

  .table {
    width: 100% !important;
    table-layout: fixed;
    border-collapse: collapse;
    border: 1em solid $light-pink !important;
    background-color: $light-pink;
  }
  .head { 
    font: 10px hk_groteskregular; 
    color: $brown;
    @include mappy-bp(max-width 489px) {
      font-size: 9px;
    } 
    @include mappy-bp(max-width 375px) {
      font-size: 8px;
    }  
  }
  .body { 
    font: 10px hk_groteskregular;
    @include mappy-bp(max-width 489px) {
      font-size: 9px;
    } 
    @include mappy-bp(max-width 375px) {
      font-size: 8px;
    }   
  }
  tr.row {
    margin: 0 !important;
    padding: 0.5em;
    background-color: $white;
    border-bottom: 1em solid $light-pink;
    border-top: 1em solid $light-pink;
  }
  tr.row:before { content: normal }

  .cell { 
    padding: 1em;
    text-align: center;
    font-size: 1.5em;

    @include mappy-bp(max-width 686px) {
      font-size: 1em;
    }
  }

  .order-numebr {
    text-decoration: underline;

    a:hover {
      color: $astral;
    }
  }

  .upperCase::first-letter { text-transform: uppercase }
  .orderNumber { text-decoration: underline; cursor: pointer }
  .green { color: $green }
  .pending { color: $rage-orange }
  .headCell { 
    text-align: center; 
    padding: 1.25em;
    font-size: 1.5em;
    @include mappy-bp(max-width 489px) {
      padding: 5px;
      font-size: 1em;
    } 
  }
  .cellLeft { text-align: left }
  .number { width: 14% }
  .date { width: 15% }
  .status { width: 14% }
  .payment { width: 18% }
  .shipment { width: 14% }
  .tracking { width: 15% }
  .total { width: 10% }
  tr > th {
    line-height: 1 !important;
  }
}

.account-container {
  padding-left: 15px;
  padding-right: 15px;
  @include mappy-bp(max-width 489px) {
    padding-left: 5px;
    padding-right: 5px;
  } 
}

@include media('<=tablet') {
  .account-page {
    .cell {
      padding: 5px 0;
    }

    .order-container {
      margin-bottom: 40px;
    }

    .table {
      border: 5px solid $light-pink !important;
      border: 0;
    }

    tr.row {
      padding: 8px 5px 5px 14px;
      padding: 0;
    }

    td {
      word-wrap: break-word;
    }

    .cellLeft {
      text-align: center;
      padding-left: 8px;
      @include mappy-bp(max-width 489px) {
        padding-left: 0;
      } 
    }

    .cellRight {
      padding-right: 8px;
      @include mappy-bp(max-width 489px) {
        padding-right: 0;
      } 
    }
  }
}

@include media('<=phone') {
  .account-page {
    .separatorPadd {
      flex-flow: row wrap;
    }
    .table {
      border: none !important;
    }
  }
}
